.overlay_statement {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 100%;
    /*width: 100%;*/
    /*position: fixed; !* Stay in place *!*/
    /*z-index: 1; !* Sit on top *!*/
    /*left: 0;*/
    /*top: 0;*/
    /*width: 100%;*/
    padding: 0;
    width: 100%;
    /*height: 100vh;*/
    /*overflow: hidden;*/
    margin: 0;
    /*background: rgb(135, 206, 235) ;*/
    background: #f5f5f5 ;
}

.card_statement{
    /*height: auto;*/
    margin: 0 !important;
    /*margin: 0;*/
    padding: 0;
    width: 33.333vw;
    height: 32vw;
    /*width: 33.333%;*/
    /*height: auto;*/
    border: 0;
    border-radius: 0;
    /*padding-bottom:33.333%;*/
    /*bg-dark*/
}
.top_image_odd{
    background-color: rgb(135, 206, 235, 0.1);
    text-align: center;
}

.top_image_even{
    /*background-color: rgb(135, 206, 235, 0.1);*/
    text-align: center;
}

.main_col{
    padding: 0;
    overflow: hidden;
    z-index: 1
}

/*.top_image_odd{*/
/*    visibility: hidden;*/
/*}*/

.top_image_odd:hover{
    visibility: visible;
    /*background: yellow;*/
    background-color: rgb(135, 206, 235, 0.9);

}
.top_image_odd:hover .text_card{
    visibility: visible;
    /*background: yellow;*/
    /*background-color: rgb(135, 206, 235, 0.9);*/

}

.top_image_even:hover{
    background-color: rgb(135, 206, 235, 0.9);
    visibility: visible;
}

.top_image_even:hover .text_card{
    visibility: visible;
    /*background: yellow;*/
    /*background-color: rgb(135, 206, 235, 0.9);*/

}
.text_card {
    /*color: #f5f5f5;*/
    visibility: hidden;
    /*visibility: visible;*/
    /*display: inline-block;*/
    /*vertical-align: middle;*/
    /*height: 100px;*/
    /*width: 100px;*/
    /*display:table-cell;*/
    /*vertical-align:middle;*/
    /*margin: 0;*/
    /*position: absolute;*/
    /*margin-top: 40%;*/
    padding-top: 40%;
    /*top: 50%;*/
    /*-ms-transform: translateY(-50%);*/
    /*transform: translateY(-50%);*/
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    color: rgb(4, 36, 140);
    letter-spacing: 1px;
}

@media only screen and (max-width: 992px) {
    .text_card {
        visibility: visible;
        padding-top: 40%;
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        color: rgb(4, 36, 140);
        letter-spacing: 1px;
    }
    .carousel_item{
        /*height: 300px;*/
        height: 40vh;
    }
    a {
        text-decoration: none;
        color: #1E1E23;
        opacity:1;
        font-family: 'work sans', sans serif;
        font-size: 1.5em;
        font-weight: 400;
        transition: 200ms;
    }
    a:hover {
        opacity:0.5;
    }
    ul {
        padding: 0;
        list-style-type: none;
    }



    /*.container {*/
    /*    margin-top: 50px;*/
    /*    Display: flex;*/
    /*    justify-content: center;*/
    /*    align-items: center;*/
    /*}*/
    /*.phone {*/
    /*    background-color: #36383F;*/
    /*    border-radius: 40px;*/
    /*    width: 300px;*/
    /*    height: 600px;*/
    /*    Display: flex;*/
    /*    justify-content: center;*/
    /*    align-items: center;*/
    /*    box-shadow: 30px 50px 100px #85888C;*/
    /*}*/
    /*.content {*/
    /*    border: 1px solid #36383F;*/
    /*    border-radius: 20px;*/
    /*    width: 94%;*/
    /*    height: 91%;*/
    /*    background-color: #F5F6FA;*/
    /*    overflow: hidden;*/
    /*}*/
    nav {
        /*background-color: #1E1E23;*/
        /*height: 65px;*/
    }



}