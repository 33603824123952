.overlay_total_amount{
    background: #f5f5f5 ;

}

.image_total_amt{
    max-width: 50%;
    margin: 0;
}

.main_col{
    align-content: center;
}

@media only screen and (max-width: 992px) {
    .image_total_amt{
        max-width: 90%;
        /*margin: 0;*/
    }
}