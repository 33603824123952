.overlay_donate {
    z-index: 1; /* Sit on top */
    background-color: #87CEEB;
}
.card{
    margin: 25px;
    /*background: #f5f5f5;*/
    background-color: rgb(245, 245, 245, 0.5);
}
.list-group-item{
    /*background: #f5f5f5 0.9;*/
    background-color: rgb(245, 245, 245, 0.1);
}

.title_card{
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    color: rgb(4, 36, 140);
    text-decoration-style: double;
    letter-spacing: 1px;
    /*text-decoration-style: double;*/
    font-weight: 600;
}

.h1{
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    color: rgb(4, 36, 140);
    /*text-decoration-style: double;*/
    letter-spacing: 1px;
    font-weight: bold;
}

.main_text_card{
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    color: rgb(4, 36, 140);
    letter-spacing: 1px;
}

@media only screen and (min-width: 992px) {
    .overlay {
        /*width: 100%;*/
        /*height: 100vh;*/
        /*overflow: hidden;*/
        /*background: linear-gradient(rgb(4, 36, 140) 0%, rgb(135, 206, 235) 40%, #f5f5f5 100%);*/
    }
}