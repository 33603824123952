.overlay {

    width: 100%;
    height: 100vh;
    overflow: hidden;

    /*background-color: #87CEEB;*/
/*rgb(237, 218, 221)*/
/*    FFFFFF - white*/
/*    FFFFF0 - ivory !!!!!! This is cool*/
/*    FFFF00 - yellow*/
/*    background: linear-gradient(#020024 0%,#090979 5%, rgb(4, 36, 140) 10%, rgb(135, 206, 235) 30%, #FFFFF0 100%);*/
/*    background: linear-gradient(#020024 0%,  #090979 30%, #0a31cb 50%, #FFFFF0 100%);  !*imteresting one*/
    background: linear-gradient(rgb(4, 36, 140) 0%, rgb(135, 206, 235) 40%, #f5f5f5 100%);/*to keep
/*    background: linear-gradient(#020024 0%, #090979 35%, #00d4ff 100%); !* cool one*/
/*    background-color: rgb(0,0,0); !* Black fallback color *!*/
    /*background-color: rgba(0,0,0, 0.9); !* Black w/opacity *!*/
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

img{
    /*width: 50%*/
    max-width: 100%;
    height: auto;
    display: block;
}

/*.nav_link_col{*/
/*    width: 10%;*/
/*}*/
.nav_link{
    width: 100%;
    /*background-color: #555;*/
    /*overflow: auto;*/
}
a.nav-link{
    float: left;
    padding: 12px;
    /*color: white;*/
    /*text-decoration: none;*/
    /*font-size: 16px;*/
    width: 16%; /* Four links of equal widths */
    text-align: center;

}

.navbar-light .navbar-nav .nav-link{
    font-family: 'Open Sans', sans-serif;
    color: #2a5898;
    letter-spacing: 3px;
    font-size: 18px;
    font-weight: 600;

}

.navbar-light .navbar-nav .nav-link:hover {
    color: #829fe5 !important;

}

.navbar-light .navbar-nav .nav-link.active {
    color: #829fe5 !important;
}

@media only screen and (max-width: 992px) {
    .overlay {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        background: linear-gradient(rgb(4, 36, 140) 0%, rgb(135, 206, 235) 40%, #f5f5f5 100%);
    }
    img{
        /*width: 50%*/
        height: auto;
        display: block;
        width: auto;
        max-height: 100%;
        /*object-fit: contain;*/
    }
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
    .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1;
        top: 0;
        /*left: 0;*/
        right: 0;
        /*background-color: #111;*/
        background-color: rgba(0,0,0, 0.9);
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;
    }

    .sidenav a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 25px;
        color: #818181;
        display: block;
        transition: 0.3s;
    }

    .sidenav a:hover {
        color: #d3cdcd;
    }

    .sidenav .closebtn {
        position: absolute;
        /*top: 3rem;*/
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
    }
}