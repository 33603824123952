.parallax {
    /* The image used */
    background-image: url('../../images/no_bg.png');
    /* Full height */
    height: 200px;
    /*height: 100%;*/

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /*z-index: 1;*/
}

.main_text{
    height: 70%;
    /*background-color:red;*/
    font-size:36px
}

.image{
    background-image: url('../../images/no_bg.png');
}
