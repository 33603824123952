.overlay_contact {
    letter-spacing: 1px;
    font-family: 'Open Sans', sans-serif;
    /*font-size: 15px;*/
    /*color: rgb(4, 36, 140);*/
    /*letter-spacing: 1px;*/
    background: #f5f5f5 ;
    /*background: #232323 ;*/

    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    /*height: 100%;*/
    /*width: 100%;*/
    /*position: fixed; !* Stay in place *!*/
    /*z-index: 1; !* Sit on top *!*/
    /*left: 0;*/
    /*top: 0;*/
    /*width: 100%;*/
    /*height: 100vh;*/
    /*overflow: hidden;*/

    /*background-color: #87CEEB;*/
    /*rgb(237, 218, 221)*/
    /*    FFFFFF - white*/
    /*    FFFFF0 - ivory*/
    /*    FFFF00 - yellow*/
    /*    background: linear-gradient(#020024 0%,#090979 5%, rgb(4, 36, 140) 10%, rgb(135, 206, 235) 30%, #FFFFF0 100%);*/
    /*    background: linear-gradient(#020024 0%,  #090979 30%, #0a31cb 50%, #FFFFF0 100%);  !*imteresting one*/
    /*background: linear-gradient(rgb(4, 36, 140) 0%, rgb(135, 206, 235) 40%, #FFFFF0 100%);!*to keep*/
/*    background: linear-gradient(#020024 0%, #090979 35%, #00d4ff 100%); !* cool one*/
    /*    background-color: rgb(0,0,0); !* Black fallback color *!*/
    /*background-color: rgba(0,0,0, 0.9); !* Black w/opacity *!*/
    /*overflow-x: hidden; !* Disable horizontal scroll *!*/
    /*transition: 0.5s; !* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) *!*/
}

footer p{
    color: #4b4b4b;
}

footer h5 {
    /*font-family: 'Playfair Display', serif;*/
    margin-bottom: 20px;
    position: relative;
    color: #4b4b4b;
}
footer h5:after {
    position: absolute;
    /*background: grey;*/
    width: 5vh;
    height: 1px;
    left: 0;
    bottom: -10px;
}
/*.btn-footer {*/
/*    color: grey;*/

/*    text-decoration: none;*/
/*    border: 1px solid;*/
/*    border-radius: 43px;*/
/*    font-size: 13px;*/
/*    padding: 7px 30px;*/
/*    line-height: 47px;*/
/*}*/
/*.btn-footer:hover {*/

/*    text-decoration: none;*/

/*}*/
/*.form-footer input[type="text"] {*/
/*    border: none;*/
/*    border-radius: 16px 0 0 16px;*/
/*    outline: none;*/
/*    padding-left: 10px;*/

/*}*/
/*::placeholder {*/
/*    font-size: 10px;*/
/*    padding-left: 10px;*/
/*    font-style: italic;*/
/*}*/
/*.form-footer input[type="button"] {*/
/*    border: none;*/
/*    background:#232323;*/
/*    margin-left: -5px;*/
/*    color: #fff;*/
/*    outline: none;*/
/*    border-radius: 0 16px 16px 0;*/
/*    padding: 2px 12px;*/
/*}*/
.social .fa {
    color: grey;
    font-size: 22px;
    padding-left: 0;
    padding-top: 0;
    /*padding: 10px 15px;*/
    /*background: #3c3c3c;*/
}
/*footer ul li {*/
/*    list-style: none;*/
/*    display: block;*/
/*}*/
/*footer ul  {*/
/*    padding-left: 0;*/
/*}*/
/*footer ul  li a{*/
/*    text-decoration: none;*/
/*    color: grey;*/
/*    text-decoration:none;*/
/*}*/
a:hover {
    text-decoration: none;
    color: #040b34 !important;

}
.logo-part {
    border-right: 1px solid grey;
    height: 100%;
    /*font-size: 18px;*/
    /*color: rgb(4, 36, 140);*/
    /*letter-spacing: 1px;*/
    /*font-family: 'Open Sans', sans-serif;*/
    /*!*font-size: 15px;*!*/
    /*!*color: rgb(4, 36, 140);*!*/
    /*!*letter-spacing: 1px;*!*/
}

.line{
    align-items: center;
    align-content: center;
}
.logo-photo{
    /*margin-top: 0px;*/
    /*width: 50%*/
    /*max-width: 100%;*/
    /*height: auto;*/
    /*display: block;*/
}
/*.image_contact{*/
/*    width: 50%;*/
/*}*/

.middle_contact{
    margin-top: 40px;
    /*color: #363636;*/
    color: #4b4b4b;
}

footer a.nav-link{
    color: #4b4b4b;
}

@media only screen and (max-width: 992px) {
    .logo-part {
        /*border-right: 5px solid red;*/
        border-right: 0;
        height: 100%;
    }
    .overlay_contact {
        letter-spacing: 1px;
        font-family: 'Open Sans', sans-serif;
        text-align: center;
        background: #f5f5f5;
    }
}
