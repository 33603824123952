.overlay_about {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    /*height: 100%;*/
    /*width: 100%;*/
    /*position: fixed; !* Stay in place *!*/
    z-index: 1; /* Sit on top */
    /*left: 0;*/
    /*top: 0;*/
    width: 100%;

    /*#FFFFF0 -- cool COLOUR!!!!*/
    background-color: #f5f5f5;
    /*height: 100vh;*/
    overflow: hidden;
    justify-content: center;
    /*text-align: center;*/
    /*background-color: #87CEEB;*/
    /*rgb(237, 218, 221)*/
    /*    FFFFFF - white*/
    /*    FFFFF0 - ivory*/
    /*    FFFF00 - yellow*/
    /*    background: linear-gradient(#020024 0%,#090979 5%, rgb(4, 36, 140) 10%, rgb(135, 206, 235) 30%, #FFFFF0 100%);*/
    /*    background: linear-gradient(#020024 0%,  #090979 30%, #0a31cb 50%, #FFFFF0 100%);  !*imteresting one*/
    /*background: linear-gradient(rgb(4, 36, 140) 0%, rgb(135, 206, 235) 40%, #FFFFF0 100%);!*to keep*/
/*    background: linear-gradient(#020024 0%, #090979 35%, #00d4ff 100%); !* cool one*/
    /*    background-color: rgb(0,0,0); !* Black fallback color *!*/
    /*background-color: rgba(0,0,0, 0.9); !* Black w/opacity *!*/
    /*overflow-x: hidden; !* Disable horizontal scroll *!*/
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

.about{
    text-align: center;
    max-width: 100%;
    height: auto;
    display: block;
}

hr{
    opacity: 1;
    border: 2px solid #090979;
    width: 5%;
    border-radius: 5px;
}

.about_text{
    text-align: center;
}

.p{
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    color: rgb(4, 36, 140);
    letter-spacing: 1px;
}
.h1{
    font-family: 'Open Sans', sans-serif;
    font-size: 25px;
    color: rgb(4, 36, 140);
    text-decoration-style: double;
    letter-spacing: 1px;
}

@media only screen and (max-width: 992px) {
    hr{
        opacity: 1;
        border: 2px solid #090979;
        width: 60% !important;
        border-radius: 5px;
    }
}