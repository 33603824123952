.locations_ribbon img{
    height: 80vh;
}

.locations_ribbon .modal-content{
    align-items: center;
    background: transparent;
}


.show_photos .modal-content{
    /*align-items: center;*/
}
