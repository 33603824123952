.overlay_back_to_top{
    /*background-color: rgb(135, 206, 235, 0.9);*/
    /*width: 100%;*/
    height: 10%;
    /*background: #f5f5f5 ;*/
    background-color: rgb(135, 206, 235, 0.3);

}

.the_arrow{
    font-size: 2rem;
    padding: 0;
    color: rgb(7, 91, 203);
}


.p_back_to_top {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    color: rgb(4, 36, 140);
    letter-spacing: 1px;
}